<script>
import {useTitle} from 'vue-page-title';
import {useRoute} from 'vue-router'
import Banner from '@/components/shared/home/Banner';
import Feature from '@/components/shared/home/Feature';

export default {
	name: 'Home',
	components: {
		Banner,
		Feature
	},
	methods: {
		say(message) {
			alert(message)
		},
		showAlert() {
			// Use sweetalert2
			this.$swal('Hello Vue world!!!');
		},
		toastTopEnd() {
			this.$swal({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000,
				icon: 'success',
				title: 'Hi man',
				text: 'is a good day!',
			});
		},
	},
	setup() {
        const route     = useRoute();
        const routeName = route.meta.title;
        useTitle(routeName);
    },
};
</script>

<template>
<Banner />
<Feature />
</template>