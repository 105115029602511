<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'VUE Project',
			author: 'Manonsanoi',
		};
	},
};
</script>

<template>
<p class="font-semibold font-heading">&copy; {{ copyrightDate }}. {{ projectName }} . Developed by {{ author }}</p>
</template>

<style lang="scss" scoped></style>
