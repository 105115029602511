import { createApp } from 'vue';
import { pageTitle } from 'vue-page-title';
import App from './App.vue';
import router from './router';
import BackToTop from 'vue-backtotop';
import Toast from "vue-toastification";
import { createStore } from 'vuex'
import 'vue-plyr/dist/vue-plyr.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './assets/css/app.css';
import "vue-toastification/dist/index.css";

const toastOptions = {
    position: "bottom-right",
	timeout: 1000,
	pauseOnHover: true
};

const feather = require('feather-icons');
feather.replace();

// Create a new store instance.
const store = createStore({
	state () {
		return {
			auth: false
		}
	},
	mutations: {
		increment (state,n) {
			state.auth = n
		}
	}
})

createApp(App)
.use(router)
.use(BackToTop)
.use(Toast, toastOptions)
.use(store)
.use(VueSweetalert2)
.use(pageTitle({
	suffix: '/ FTI Academy',
	mixin: true,
}))
.mount('#app');

//store.commit('increment')
console.log("Current Auth State",store.state.auth) // -> 1

const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
