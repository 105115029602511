<script>

import feather from 'feather-icons';
import Header from '@/components/shared/template/Header';
import Footer from '@/components/shared/template/Footer';

export default {
	components: {
		Header,
		Footer,
	},
	data: () => {
		return {
			appTheme: localStorage.getItem('theme'),
		};
	},
	mounted() {
		feather.replace();
	},
	updated() {
		feather.replace();
	},
};
</script>

<template>

	<Header />
	<router-view/>
	<back-to-top
		visibleoffset="500"
		right="30px"
		bottom="20px"
		class="shadow-lg"
	>
	<i data-feather="chevron-up"></i>
	</back-to-top>
	<Footer />

</template>

<style>
#app {}
.vue-back-to-top {
@apply p-2 bg-indigo-500 hover:bg-indigo-600 text-white transition
duration-500
ease-in-out
transform
hover:-translate-y-1 hover:scale-110;
border-radius: 50%;
font-size: 22px;
line-height: 22px;
}
.fade-enter-active {
animation: coming 0.4s;
animation-delay: 0.2s;
opacity: 0;
}
.fade-leave-active {
animation: going 0.4s;
}

.feather-16{
    width: 16px;
    height: 16px;
}

.feather-22{
    width: 22px;
    height: 22px;
}

.feather-24{
    width: 24px;
    height: 24px;
}

.feather-32{
    width: 32px;
    height: 32px;
}

@keyframes going {
from {
transform: translateX(0);
}
to {
transform: translateX(-10px);
opacity: 0;
}
}
@keyframes coming {
from {
transform: translateX(-10px);
opacity: 0;
}
to {
transform: translateX(0px);
opacity: 1;
}
}

.isblock
{
	pointer-events: none;
	cursor: progress;
}

.isblock:before {
	content: 'กำลังโหลดข้อมูล.....';
    opacity: 1;
    position: absolute;
    margin-left: 100px;
    margin-top: 90px;
    background: #000;
    color: #fff;
    padding: 5px 20px 5px 20px;
}

div.isblock.container{
	opacity: 0.6;
}

.swal2-popup.swal2-toast .swal2-title {
    margin: 0.1em 1em !important;
}
</style>
